<template>
  <v-card>
    <v-card-title class="headline primary white--text">
      Ajouter une pièce jointe
    </v-card-title>
    <v-card-text>
      <v-form class="px-2">
        <v-select v-if="!personalized" v-model="label" :items="labels" class="mt-2" label="Label"></v-select>
        <v-text-field v-else v-model="label" class="mt-2" label="Label"></v-text-field>
        <v-file-input v-model="file" label="Fichier"></v-file-input>
        <v-checkbox v-model="personalized" label="Mettre un nom personnalisé"></v-checkbox>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-progress-circular indeterminate color="primary" v-if="sending"/>
      <v-btn color="primary" :disabled="sending" text @click="uploadFile">Ajouter</v-btn>

    </v-card-actions>
  </v-card>
</template>
<script>
function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export default {
  name: 'PieceJointeForm',
  data() {
    return {
      file: null,
      label: null,
      personalized: false,
      sending: false,
    }
  },
  props: {
    type: String
  },
  computed: {
    labels() {
      if (this.type === "ticket") {
        return ["Photo", "Document",  "Devis", "Facture"]
      } else if (this.type === "asset") {
        return ["Photo", "Document", "Notice"]
      }else if (this.type === "contract") {
        return ["Document", "Contrat", "Devis", "Facture"]
      } else if(this.type === "profile") {
        return ["Document","KBIS", "CNI", "Assurance", "Habilitation"]
      } else {
        return [];
      }
    },
  },
  methods: {
    async uploadFile() {
      this.sending = true;
      await delay(1000);
      if (this.type === "ticket") {
        await this.uploadTicketAttachment();
      } else if (this.type === "asset") {
        await this.uploadAssetAttachment();
      } else if (this.type === "contract") {
        await this.uploadContractAttachment();
      } else if (this.type === "profile") {
        await this.uploadProfileAttachment();
      }
      this.sending = false;
    },
    async uploadTicketAttachment() {
      let ticket = this.$store.getters["tickets/getSelectedTicket"];
      let attachment = {
        ticket: ticket.id,
        file: this.file,
        label: this.label,
      }
      await this.$store.dispatch("attachments/postTicketAttachment", attachment);
      this.resetFields();
      this.$emit("close-dialog");
    },
    async uploadAssetAttachment() {
      let asset = this.$store.getters["assets/getSelectedAsset"];
      let attachment = {
        asset: asset.id,
        file: this.file,
        label: this.label,
      }
      await this.$store.dispatch("attachments/postAssetAttachment", attachment);
      this.resetFields();
      this.$emit("close-dialog");
    },
    async uploadContractAttachment() {
      let contract = this.$store.getters["contracts/getSelectedContract"];
      let attachment = {
        contract: contract.id,
        file: this.file,
        label: this.label,
      }
      await this.$store.dispatch("attachments/postContractAttachment", attachment);
      this.resetFields();
      this.$emit("close-dialog");
    },
    async uploadProfileAttachment() {
      let profile = this.$store.getters["profile/getSelectedProfile"];
      let attachment = {
        profile: profile.profile.id,
        file: this.file,
        label: this.label,
      }
      await this.$store.dispatch("attachments/postProfileAttachment", {attachment, profile});
      this.resetFields();
      this.$emit("close-dialog");
    },
    resetFields() {
      this.file = null;
      this.label = null;
    }
  }
}
</script>
