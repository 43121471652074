<template>
  <v-card class="mx-0 pa-2" elevation="0" v-if="selected_intervenant !== null" outlined>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col cols="3">
            <h3 class="secondary--text" v-if="hasPrestataire(selected_intervenant)">{{
                prestataire.raison_sociale
              }}</h3>
            <h3 class="primary--text">{{ selected_intervenant.first_name }} {{ selected_intervenant.last_name }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-btn v-if="isResponsableOrAdmin || hasPermission('change_profile')" @click="editIntervenant()" text color="primary">
              <v-icon>mdi-pencil</v-icon>
              Modifier
            </v-btn>
            <v-dialog v-model="deleteDialog" v-if="isResponsableOrAdmin || hasPermission('delete_profile')" width="500">
              <template v-slot:activator="{on, attrs}">
                <v-btn text v-on="on" v-bind="attrs" color="error">
                  <v-icon>mdi-trash-can-outline</v-icon>
                  Supprimer
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline red lighten-1 white--text">
                  Supprimer l'intervenant
                </v-card-title>
                <v-card-text class="body-1 mt-2">
                  Confirmer la suppression de l'intervenant ?
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="deleteIntervenant" text color="red">Supprimer l'intervenant
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-2"
             v-if="hasProfile(selected_intervenant) && selected_intervenant.profile.metiers !== null">
        <v-chip color="primary" :key="metier.id" v-for="metier in selected_intervenant.profile.metiers">
          {{ metier.name }}
        </v-chip>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-email" name="E-mail"
                     :information="selected_intervenant.email"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-account-multiple" name="Groupe"
                     :information="selected_intervenant.group"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-cellphone" name="Tel mobile"
                     :information="hasProfile(selected_intervenant)
                                   && selected_intervenant.profile.tel_mobile !== null
                                   ? selected_intervenant.profile.tel_mobile : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-phone" name="Tel fixe"
                     :information="hasProfile(selected_intervenant)
                                   && selected_intervenant.profile.tel_fixe !== null
                                   ? selected_intervenant.profile.tel_fixe : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="12">
        <v-row justify="center">
          <v-col v-for="site in selected_intervenant.profile.sites" :key="site.id" cols="6" xl="4">
            <detail-card name="Site" icon="mdi-map-marker" :information="site_name(site)"></detail-card>
          </v-col>
        </v-row>
      </v-col>
      <v-row no-gutters v-if="hasPrestataire(selected_intervenant)">
        <v-col cols="12">
          <span class="font-weight-bold">Prestataire</span>
        </v-col>
        <v-col cols="6" xl="3">
          <detail-card icon="mdi-map-marker" name="Adresse" long
                       :information="hasPrestataire(selected_intervenant)
                                       && prestataire.adresse !== null
                                       ? prestataire.adresse : 'Non défini'"></detail-card>
        </v-col>
        <v-col cols="6" xl="3">
          <detail-card icon="mdi-google-maps" name="Code postal"
                       :information="hasPrestataire(selected_intervenant)
                                   ? prestataire.code_postale
                                   : 'Non défini'"></detail-card>
        </v-col>
        <v-col cols="6" xl="3">
          <detail-card icon="mdi-office-building" name="SIRET"
                       :information="hasPrestataire(selected_intervenant)
                                   ? prestataire.siret
                                   : 'Non défini'"></detail-card>
        </v-col>
        <v-col cols="12" xl="6">
          <detail-card icon="mdi-ticket" name="Reçoit directement les tickets"
                       :information="hasPrestataire(selected_intervenant)
                                   ? prestataire.direct_ticket ? 'Oui' : 'Non'
                                   : 'Non défini'"></detail-card>
        </v-col>
        <v-col cols="12" v-if="prestataire.contrats">
          <v-row v-if="prestataire.contrats.length > 0">
            <v-col cols="12">
              <span class="font-weight-bold">Contrats du prestataire</span>
            </v-col>
            <v-col cols="4" v-for="contrat in prestataire.contrats" :key="contrat.id">
              <detail-card clickable
                           :name="getContractType(contrat)" long
                           :information="contrat.name"
                           @click.native="$router.push('/contracts/' + contrat.id)"
                           icon="mdi-file-document-multiple-outline"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="selected_intervenant.interventions.length > 0">
          <v-row>
            <v-col cols="12">
              <span class="font-weight-bold">Interventions du prestataire</span>
            </v-col>
            <v-data-iterator :items-per-page="4" :items="selected_intervenant.interventions">
              <template v-slot:default="{items}">
                <v-row class="pa-2">
                  <v-col cols="3" v-for="item in items" :key="item.id">
                    <detail-card clickable
                                 :name="'Intervention #' + item.id"
                                 :information="item.objet"
                                 @click.native="$router.push('/interventions/' + item.id)"
                                 icon="mdi-file-document-multiple-outline"/>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-row>
        </v-col>
      </v-row>
      <v-col cols="12" v-if="hasProfile(selected_intervenant)">
        <v-row no-gutters>
          <v-col cols="10">
            <p class="body-1"><strong>{{ selected_intervenant.profile.documents.length }} pièce<span
                v-if="selected_intervenant.profile.documents.length > 1">s</span> jointe<span
                v-if="selected_intervenant.profile.documents.length > 1">s</span> </strong></p>
          </v-col>
          <v-col cols="2">
            <v-dialog v-model="attachmentDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" elevation="2" color="primary" fab>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <PieceJointeForm @close-dialog="attachmentDialog = false;" type="profile"/>
            </v-dialog>
          </v-col>
          <v-col cols="6" xl="3" class="mb-2 pa-0 mini-card"
                 v-for="attachment in selected_intervenant.profile.documents"
                 :key="attachment.id">
            <v-row>
              <v-col cols="10" class="pa-0">
                <v-list-item two-line :href="attachment.file" target="_blank">
                  <v-list-item-icon>
                    <v-icon>mdi-file</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ attachment.label }}</v-list-item-title>
                    <v-list-item-subtitle>{{ getFilename(attachment.path) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col class="align-center justify-center pa-0" cols="2">
                <v-icon @click="deleteAttachment(attachment)">mdi-trash-can-outline</v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row no-gutters class="py-4 ml-3">
      <v-col>
        <!--<v-btn @click="changeState('finished')"
               v-if="selected_ticket.state !== 'finished' && selected_ticket.state !== 'closed'"
               class="green darken-3" light>
          <span class="white--text"><v-icon left>mdi-check</v-icon>Passer en "réalisé"</span>
        </v-btn>-->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DetailCard from "@/components/base/DetailCard";
import PieceJointeForm from "@/components/tickets/PieceJointeForm";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import {ContractMixin} from "@/mixins/contracts/ContractMixin";

export default {
  name: "IntervenantView",
  components: {DetailCard, PieceJointeForm},
  mixins: [AuthMixin, ContractMixin],
  async mounted() {
    if (this.$route.params?.id !== undefined) {
      await this.$store.dispatch("profile/getProfileById", this.$route.params?.id);
    }
  },
  data() {
    return {
      attachmentDialog: false,
      deleteDialog: false,
    }
  },
  computed: {
    selected_intervenant() {
      return this.$store.getters["profile/getSelectedProfile"];
    },
    prestataire() {
      return this.selected_intervenant.profile.prestataire;
    }

  },
  methods: {
    hasProfile(intervenant) {
      return intervenant.profile !== null;
    },
    hasPrestataire(intervenant) {
      return this.hasProfile(intervenant) && intervenant.profile.prestataire !== null;
    },
    editIntervenant() {
      this.$emit("edit-intervenant", this.selected_intervenant.id);
    },
    async deleteIntervenant() {
      await this.$store.dispatch("profile/deleteProfile", this.selected_intervenant);
      this.deleteDialog = false;
    },
    getFilename(path) {
      let elements = path.split("/")
      return elements[elements.length - 1]
    },
    async deleteAttachment(attachment) {
      let profile = this.selected_intervenant;
      let result = await this.$store.dispatch("attachments/deleteProfileAttachment", {attachment, profile});
      if (result === "success") {
        this.$store.dispatch("messages/setAnnounce", {"type": "success", "message": "Document supprimé."});
      } else {
        this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue. Le document n'a peut être pas été correctement supprimé."
        });
      }
    },
    site_name(site) {
      let siteObject = site;
      if (siteObject === null || siteObject === undefined) return "Non défini";
      let siteText = siteObject.name;
      if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
      }
      if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
      }
      return siteText;
    },
  }
}
</script>

<style scoped>

</style>