<template>
  <v-list-item :two-line="!long" :three-line="long" :tabindex="clickable === true">
    <v-list-item-icon>
      <v-icon class="pt-1 pb-0">{{icon}}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{name}}
      </v-list-item-title>
      <v-list-item-subtitle>{{ information }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "DetailCard",
  props: {
    icon: String,
    name: String,
    information: String,
    clickable: Boolean,
    long: Boolean
  }
}
</script>

<style scoped>

</style>